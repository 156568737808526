import apteraLogo from '../public/assets/images/aptera_logo.svg'
import decathlonLogo from '../public/assets/images/decathlon-logo.svg'
import senseFlyLogo from '../public/assets/images/sensefly.png'
import mvAgustaLogo from '../public/assets/images/mv-agusta.svg'

const heroVideos = [
  {
    h264: 'https://airshaper.storage.googleapis.com/website/video/cooler.h264.mp4',
    av1: 'https://airshaper.storage.googleapis.com/website/video/cooler.h264.mp4',
    hevc: 'https://airshaper.storage.googleapis.com/website/video/cooler.h264.mp4',
    logo: null,
    text: '',
    client: '',
    link: '',
    imgWidth: 150,
    imgHeight: 100,
  },
  {
    h264: 'https://airshaper.storage.googleapis.com/website/video/decathlon.h264.mp4',
    av1: 'https://airshaper.storage.googleapis.com/website/video/decathlon.av1.mp4',
    hevc: 'https://airshaper.storage.googleapis.com/website/video/decathlon.hevc.mp4',
    logo: decathlonLogo,
    text: 'Improving rider comfort',
    client: 'Decathlon',
    link: 'https://www.decathlon.com/',
    imgWidth: 125,
    imgHeight: 72,
  },
  {
    h264: 'https://airshaper.storage.googleapis.com/website/video/sensefly.h264.mp4',
    av1: 'https://airshaper.storage.googleapis.com/website/video/sensefly.av1.mp4',
    hevc: 'https://airshaper.storage.googleapis.com/website/video/sensefly.hevc.mp4',
    logo: senseFlyLogo,
    text: 'Extending drone range',
    client: 'SenseFly',
    link: 'https://www.sensefly.com/',
    imgWidth: 150,
    imgHeight: 46,
  },
  {
    h264: 'https://airshaper.storage.googleapis.com/website/video/aptera.h264.mp4',
    av1: 'https://airshaper.storage.googleapis.com/website/video/aptera.av1.mp4',
    hevc: 'https://airshaper.storage.googleapis.com/website/video/aptera.hevc.mp4',
    logo: apteraLogo,
    text: 'The Most Efficient EV',
    client: 'Aptera',
    link: 'https://www.aptera.us/',
    imgWidth: 150,
    imgHeight: 46,
  },
  {
    h264: 'https://airshaper.storage.googleapis.com/website/video/mvagusta.h264.mp4',
    av1: 'https://airshaper.storage.googleapis.com/website/video/mvagusta.av1.mp4',
    hevc: 'https://airshaper.storage.googleapis.com/website/video/mvagusta.hevc.mp4',
    logo: mvAgustaLogo,
    text: 'Increasing front end downforce',
    client: 'MV Agusta',
    link: 'https://www.mvagusta.com/',
    imgWidth: 150,
    imgHeight: 100,
  },
]

export default heroVideos
